import React, { Component } from "react";
import { Route, BrowserRouter, Redirect } from "react-router-dom";
import { decorate, observable, action } from "mobx";
import {
  ROUTE_NAME_DOCUMENT_SEARCH,
  TITLE_STH_WENT_WRONG,
  ROUTE_NAME_DOCUMENT_UPLOAD,
  ROUTE_NAME_TERMS_CONDITIONS,
  ROUTE_NAME_PODCASTS,
  ROUTE_NAME_ACCESS_CONTROL,
  ROUTE_NAME_WATERMARK,
  ROUTE_NAME_PDF_VIEWER,
  ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL,
  ROUTE_NAME_CREATE_USER,
  PATH_NAME_DOCUMENT_SEARCH,
  ROUTE_NAME_PODCAST_UPLOAD,
  ROUTE_NAME_PODCAST_LIST,
  ROUTE_NAME_PODCAST_EDIT,
} from "./constants/strings";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faUser,
  faStamp,
  faEnvelopeOpenText,
  faTrash,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import {
  faFilePdf,
  faShare,
  faSlidersH,
  faUndo,
  faSearch,
  faCalendar,
  faTimes,
  faTimesCircle,
  faInfoCircle,
  faShareAlt,
  faFileDownload,
  faCheckCircle,
  faExclamationCircle,
  faCircle,
  faExclamationTriangle,
  faUserCircle,
  faSignOutAlt,
  faCaretLeft,
  faCaretRight,
  faBackspace,
  faFileUpload,
  faArrowAltCircleLeft,
  faPlusSquare,
  faPodcast,
  faVolumeUp,
  faStepForward,
  faStepBackward,
  faAngleLeft,
  faCloudUploadAlt,
  faKey,
} from "@fortawesome/free-solid-svg-icons";
import {
  faPlayCircle,
  faPauseCircle,
} from "@fortawesome/free-regular-svg-icons";
import Auth from "@aws-amplify/auth";
import toastify from "./backends/toastify";
import ErrorBoundary from "./ErrorBoundary";
import ResponseModal from "./components/ResponseModal";
import AuthenticationStore from "./stores/AuthenticationStore";
import DocumentSearchStore from "./stores/DocumentSearchStore";
import DocumentUploadStore from "./stores/DocumentUploadStore";
import MainApp from "./components/MainApp";
import MainLoginPage from "./components/MainLoginPage";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AmplitudeAnalytics from "./backends/amplitudeAnalytics";
import { ToastContainer } from "react-toastify";
import PDFViewerNewTab from "./components/PDFViewerNewTab";
import PodcastEdit from "./modules/PodcastUpload/Edit";
import PodcastSearchStore from "./stores/PodcastSearchStore";

/**
 * Build a local library of Fontawesome icons to be used
 * throughout this app. Simply pass the solid svg icons' names
 */
library.add(
  fab,
  faEdit,
  faTrash,
  faFilePdf,
  faShare,
  faSlidersH,
  faUndo,
  faSearch,
  faCalendar,
  faTimes,
  faTimesCircle,
  faInfoCircle,
  faCheckCircle,
  faShareAlt,
  faFileDownload,
  faExclamationCircle,
  faCircle,
  faExclamationTriangle,
  faUserCircle,
  faSignOutAlt,
  faCaretLeft,
  faCaretRight,
  faBackspace,
  faFileUpload,
  faArrowAltCircleLeft,
  faPlusSquare,
  faPodcast,
  faPlayCircle,
  faPauseCircle,
  faVolumeUp,
  faStepForward,
  faStepBackward,
  faAngleLeft,
  faCloudUploadAlt,
  faBars,
  faKey,
  faUser,
  faStamp,
  faWhatsapp,
  faEnvelopeOpenText
);

/**
 * @desc MobX decorate method is called to decorate (place decorators)
 * all or individual members of a class
 *
 * @param {object} object - Name of the target class you wanna decorate
 * @param {object} decorators - JSON containing members of the class you wanna decorate
 */
decorate(AuthenticationStore, {
  email: observable,
  setEmail: action,
  clearEmail: action,
});

decorate(DocumentUploadStore, {
  tagify: observable,
});

decorate(DocumentSearchStore, {
  apiCallActive: observable,
  openPDFModal: observable,
  filters: observable,
  filterPanel: observable,
  results: observable,
  resultViewer: observable,
  pagination: observable,
  previewActive: observable,
  snippets: observable,
  setApiCallActive: action,
  setFilters: action,
  setResults: action,
  setResultViewer: action,
  setSnippets: action,
});

decorate(PodcastSearchStore, {
  apiCallActive: observable,
  openPDFModal: observable,
  filters: observable,
  filterPanel: observable,
  results: observable,
  resultViewer: observable,
  pagination: observable,
  previewActive: observable,
  snippets: observable,
  setApiCallActive: action,
  setFilters: action,
  setResults: action,
  setResultViewer: action,
  setSnippets: action,
});

//The store to be used inside the Document Search module
const authenticationStore = new AuthenticationStore();

//The store to be used inside the Document Search module
const documentSearchStore = new DocumentSearchStore();

//The store to be used inside the Document Search module
const podcastSearchStore = new PodcastSearchStore();

//The store to be used inside the Document Search module
const documentUploadStore = new DocumentUploadStore();

//Instantiate Amplitude Analytics object
const amplitudeAnalytics = new AmplitudeAnalytics();

//toastify is initialized here, a local extension of 'react-toastify' library
const libToastify = new toastify();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      user: null,
      windowHeight: undefined,
      windowWidth: undefined,
    };
  }

  /**
   * @type {object} routes - This array contains configuration objects which map Routes to Modules,
   * along-with additional meta-data
   */
  routes = [
    {
      path: "/",
      component: MainLoginPage,
      exact: true,
      name: "mainPage",
      authRequired: false,
      customProps: {
        title: "Login",
        name: "mainPage",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/login",
      component: MainLoginPage,
      exact: true,
      name: "login",
      authRequired: false,
      customProps: {
        title: "Login to your account",
        name: "login",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/signup",
      component: MainLoginPage,
      exact: true,
      name: "signUp",
      authRequired: false,
      customProps: {
        title: "Welcome",
        name: "signUp",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/resendmail",
      component: MainLoginPage,
      exact: true,
      name: "resendMail",
      authRequired: false,
      customProps: {
        title: "Resend link",
        name: "resendMail",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/create_password",
      component: MainLoginPage,
      exact: true,
      name: "createPassword",
      authRequired: false,
      customProps: {
        title: "Generate Password",
        name: "createPassword",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/forgot_password",
      component: MainLoginPage,
      exact: true,
      name: "forgotPassword",
      authRequired: false,
      customProps: {
        title: "Request new password",
        name: "forgotPassword",
        store: authenticationStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: `${PATH_NAME_DOCUMENT_SEARCH}`,
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_DOCUMENT_SEARCH,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_DOCUMENT_SEARCH,
        store: documentSearchStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: `/research`,
      component: MainApp,
      exact: true,
      name: "research",
      authRequired: true,
      customProps: {
        name: "Research a Report",
        store: documentSearchStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/documentupload",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_DOCUMENT_UPLOAD,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_DOCUMENT_UPLOAD,
        store: documentUploadStore,
      },
    },
    {
      path: "/podcastupload",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_PODCAST_UPLOAD,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_PODCAST_UPLOAD,
        store: documentUploadStore,
      },
    },
    {
      path: "/podcastlist",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_PODCAST_LIST,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_PODCAST_LIST,
        store: documentUploadStore,
      },
    },
    {
      path: `/podcastedit/:podcast_id`,
      component: PodcastEdit,
      exact: true,
      name: ROUTE_NAME_PODCAST_EDIT,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_PODCAST_EDIT,
        store: documentSearchStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/accesscontrol",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_ACCESS_CONTROL,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_ACCESS_CONTROL,
      },
    },
    {
      path: "/podcasts",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_PODCASTS,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_PODCASTS,
        store: podcastSearchStore,
      },
    },
    {
      path: "/terms_and_conditions",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_TERMS_CONDITIONS,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_TERMS_CONDITIONS,
      },
    },
    {
      path: "/watermark",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_WATERMARK,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_WATERMARK,
      },
    },
    {
      path: "/createuser",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_CREATE_USER,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_CREATE_USER,
      },
    },
    {
      path: `/pdf-viewer/:doc_id`,
      component: PDFViewerNewTab,
      exact: true,
      name: ROUTE_NAME_PDF_VIEWER,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_PDF_VIEWER,
        store: documentSearchStore,
        amplitudeAnalytics: amplitudeAnalytics,
      },
    },
    {
      path: "/download-accesscontrol",
      component: MainApp,
      exact: true,
      name: ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL,
      authRequired: true,
      customProps: {
        name: ROUTE_NAME_DOWNLOAD_ACCESS_CONTROL,
      },
    },
  ];

  setAuthStatus = (authenticated) => {
    this.setState({ isAuthenticated: authenticated });
  };

  setUser = (user) => {
    try {
      this.setState({ user: user });

      if (user && authenticationStore.user.email.length === 0) {
        authenticationStore.setUser({
          email: user.attributes.email,
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  handleResize = () =>
    this.setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.authenticateUser().then(() => {});
  }

  async authenticateUser() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user && authenticationStore.user.email.length === 0) {
        authenticationStore.setUser({
          email: user.attributes.email,
        });
      }

      this.setState({
        user: user,
        isAuthenticated: true,
        isAuthenticating: false,
      });
    } catch (error) {
      this.setState({
        user: null,
        isAuthenticated: false,
        isAuthenticating: false,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const authProps = {
      isAuthenticated: this.state.isAuthenticated,
      user: this.state.user,
      setAuthStatus: this.setAuthStatus,
      setUser: this.setUser,
      signOutUser: this.signOutUser,
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App">
          {/*Routing is handled by this component*/}
          <BrowserRouter>
            {
              /**
               * Looping over the routes array defined at the top and spitting out
               * <Route/> component for each of our modules defined in this project
               */
              this.routes.map(
                ({
                  path,
                  component: C,
                  exact,
                  name,
                  customProps,
                  authRequired,
                }) => (
                  <Route
                    path={path}
                    exact={exact}
                    key={name}
                    render={(props) => {
                      if (authRequired) {
                        if (!this.state.isAuthenticated) {
                          // set url to redirect back to after logging in
                          if (authenticationStore.redirectRoute === "") {
                            authenticationStore.setRedirectRoute(
                              window.location.href.split(
                                window.location.origin
                              )[1]
                            );
                          }
                          return <Redirect to="/login" />;
                        } else {
                          // reset redirection url
                          if (path === "/research") {
                            return (
                              <Redirect to={`${PATH_NAME_DOCUMENT_SEARCH}`} />
                            );
                          }
                          if (authenticationStore.redirectRoute) {
                            authenticationStore.setRedirectRoute("");
                          }

                          return (
                            <ErrorBoundary
                              render={(error, errorInfo) => (
                                <ResponseModal
                                  modal={true}
                                  backdrop="static"
                                  modalClass="response-modal"
                                  titleClass="title error"
                                  modalTitle={TITLE_STH_WENT_WRONG}
                                  textClass="text"
                                  modalText={errorInfo}
                                  buttonClass="action-button error"
                                  buttonText="Reload"
                                />
                              )}
                            >
                              <C
                                {...props}
                                {...customProps}
                                auth={authProps}
                                toastify={libToastify}
                              />
                            </ErrorBoundary>
                          );
                        }
                      } else {
                        // only for dev
                        // if (path == "/") {
                        //     window.location.href = 'https://www.avendus.com/india/institutional-equities';
                        //     return null;
                        // }
                        return (
                          <C
                            {...props}
                            {...customProps}
                            auth={authProps}
                            toastify={libToastify}
                          />
                        );
                      }
                    }}
                  />
                )
              )
            }
          </BrowserRouter>
          <ToastContainer />
        </div>
      )
    );
  }
}

export default App;
