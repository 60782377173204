import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Form,
  FormGroup,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap";
import Validate from "../../utils/FormValidation";
import Auth from "@aws-amplify/auth";
import ResponseModal from "../../components/ResponseModal";
import { Link } from "react-router-dom";
import axios from "axios";

/**
 * RequestVerificationCode Component
 * Handles requesting a verification code for the forgot password process.
 */
class RequestVerificationCode extends Component {
  state = {
    email: "", // Stores the user's email input
    isLoading: false, // Tracks the loading state for submit action
    modalOpen: false, // Tracks modal visibility
    resendLoader: false, // Tracks loading state for resend mail
    modal: {
      status: "", // Modal status (e.g., success, error)
      title: "", // Modal title
      text: "", // Modal content text
      buttonText: "", // Modal button text
      functionalBtn: {}, // Additional functional button for modal
    },
    errors: {
      emailInvalid: false, // Tracks if email input is invalid
      cognito: null, // Stores Cognito-related errors
    },
  };

  // Clears all error states
  clearErrorState = () => {
    this.setState({
      responseText: "",
      errors: {
        cognito: null,
        emailInvalid: false,
      },
    });
  };

  /**
   * Handles the forgot password process.
   * Validates the email and interacts with AWS Cognito.
   */
  forgotPasswordHandler = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    this.clearErrorState();

    // Validate email input
    const errors = Validate("requestVerificationCode", this.state);
    if (Object.keys(errors).length) {
      this.setState({ errors: { ...errors }, isLoading: false });
      return;
    }

    try {
      // Request verification code via AWS Cognito
      await Auth.forgotPassword(this.state.email.toLowerCase());
      this.setState({ isLoading: false });
      this.props.setEmail(this.state.email.toLowerCase());

      // Show success modal
      this.showPopUpMessageModal(
        "success",
        "One more step",
        "We have sent a verification code to your E-mail ID. Please copy it to the next screen along with your new password to complete the process!",
        "",
        {
          onClickHandler: () =>
            this.props.changeScreen("forgotPasswordVerification"),
          text: "Okay",
          className: "main-button",
        }
      );
    } catch (error) {
      // Handle errors and display appropriate messages in the modal
      this.handleCognitoError(error);
      this.setState({ isLoading: false });
      console.error(error);
    }
  };

  /**
   * Handles AWS Cognito-specific errors and displays relevant modal messages.
   */
  handleCognitoError = (error) => {
    let message,
      buttonText,
      functionalBtn,
      fromSpark = "",
      status = "warning";

    console.log("Error :: ", error);

    switch (error.code) {
      case "NotAuthorizedException":
        message =
          error.message === "User is disabled"
            ? "Restricted access. Please contact your account manager."
            : "It seems like you've not completed the sign-up process. Click here to generate a fresh password.";
        functionalBtn = {
          onClickHandler: this.handleResendMail,
          text: "Resend link",
          className: "action-button warning",
        };
        break;
      case "UserNotFoundException":
        message =
          "We couldn’t verify your account. Please try signing up or contact your sales representative.";
        functionalBtn = {
          onClickHandler: () => this.props.history.push("/signup"),
          text: "Create a New Account",
          className: "action-button warning",
        };
        fromSpark = "Message from Avendus Spark";
        break;
      case "CodeDeliveryFailureException":
        message =
          "We couldn't send the verification code. Please check the email entered and try again.";
        buttonText = "OKAY";
        break;
      case "InternalErrorException":
        message = "Our servers are temporarily down. Please try again later.";
        buttonText = "TRY AGAIN";
        break;
      case "LimitExceededException":
        message =
          "Password reset attempt limit exceeded. Please try again after some time.";
        buttonText = "OKAY";
        break;
      default:
        message = "Something went wrong. Please try again later.";
        buttonText = "OKAY";
        break;
    }

    this.showPopUpMessageModal(
      status,
      fromSpark ? fromSpark : "Oops!",
      message,
      buttonText,
      functionalBtn
    );
  };

  /**
   * Resends a verification email by interacting with a custom API.
   */
  handleResendMail = async () => {
    this.setState({ resendLoader: true });

    try {
      const params = { email: this.state.email.toLowerCase() };
      const url = `${process.env.REACT_APP_LIB_DOMAIN}/resend_email`;
      const response = await axios.post(url, params);

      if (response?.status === 200 && response.data?.status) {
        this.showPopUpMessageModal(
          "success",
          "Mail sent!",
          "Please generate a new password using the link sent to your E-mail.",
          "OKAY"
        );
      } else {
        this.showErrorModal();
      }
    } catch (error) {
      console.error(error);
      this.showErrorModal();
    }

    this.setState({ resendLoader: false });
  };

  /**
   * Displays a generic error modal for email resend failures.
   */
  showErrorModal = () => {
    this.showPopUpMessageModal(
      "error",
      "Oops!",
      "We couldn't recognize you. Please try signing up again or contact your account manager.",
      "TRY AGAIN"
    );
  };

  /**
   * Shows a modal with the provided details.
   */
  showPopUpMessageModal = (
    status,
    modalTitle,
    modalText,
    buttonText,
    functionalBtn
  ) => {
    this.setState({
      modalOpen: true,
      modal: {
        status,
        title: modalTitle,
        text: modalText,
        buttonText,
        functionalBtn,
      },
    });
  };

  // Handles input field changes
  onInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Closes the modal
  onActionButtonClick = () => {
    this.setState({ modalOpen: false });
  };

  // Submits the form when the Enter key is pressed
  onKeyPress = (e) => {
    if (e.key === "Enter") this.forgotPasswordHandler(e);
  };

  render() {
    const { errors, isLoading, modalOpen, modal, resendLoader } = this.state;

    return (
      <Fragment>
        <Form>
          <FormGroup>
            <div className="label-container">
              <span className="field-label">E-mail</span>
            </div>
            <Input
              type="text"
              name="email"
              placeholder="example@abc.com"
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={errors.emailInvalid}
            />
            <FormFeedback>
              {errors.emailInvalid && "Please Enter a valid Email"}
            </FormFeedback>
          </FormGroup>
          <div className="response-text">
            {errors.cognito && errors.cognito.message}
          </div>
          <Button
            style={{
              backgroundColor: "#2b2e32",
              margin: "10px 0",
              width: "100%",
            }}
            onClick={this.forgotPasswordHandler}
          >
            {isLoading ? <Spinner size="sm" color="white" /> : "SUBMIT"}
          </Button>
          <div style={{ margin: "-5px 0 -2px 0" }}>
            <Link className="back-to-login-link" to="/login">
              Go back to Login
            </Link>
          </div>
        </Form>
        {modalOpen && (
          <ResponseModal
            modal={modalOpen}
            backdrop={true}
            modalClass="response-modal"
            titleClass={`title ${modal.status}`}
            modalTitle={modal.title}
            textClass="text"
            modalText={modal.text}
            buttonClass={`action-button ${modal.status}`}
            buttonText={modal.buttonText}
            onClickAction={this.onActionButtonClick}
            functionalBtn={modal.functionalBtn}
            isBtnLoading={resendLoader}
            type={modal.status}
          />
        )}
      </Fragment>
    );
  }
}

export default observer(RequestVerificationCode);
