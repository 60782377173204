import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import ForgotPasswordVerification from "../ForgotPasswordVerification";
import RequestVerificationCode from "../RequestVerificationCode";

/**
 * ForgotPassword Component
 * Handles the forgot password flow, including requesting a verification code
 * and verifying the code to reset the password.
 */
class ForgotPassword extends Component {
  state = {
    email: "", // Stores the user's email address
    screen: "requestVerificationCode", // Tracks the current screen to display
  };

  /**
   * Changes the currently displayed screen.
   * @param {string} screen - The screen to switch to (e.g., 'requestVerificationCode', 'forgotPasswordVerification').
   */
  changeScreen = (screen) => {
    this.setState({ screen });
  };

  /**
   * Updates the email state.
   * @param {string} email - The email address to set.
   */
  setEmail = (email) => {
    this.setState({ email });
  };

  render() {
    const { screen, email } = this.state; // Destructure state for clarity

    return (
      <Fragment>
        {/* Display the RequestVerificationCode component if the current screen is 'requestVerificationCode' */}
        {screen === "requestVerificationCode" && (
          <RequestVerificationCode
            {...this.props} // Pass down all props to the child component
            changeScreen={this.changeScreen} // Pass function to change screens
            setEmail={this.setEmail} // Pass function to update the email
          />
        )}
        {/* Display the ForgotPasswordVerification component if the current screen is 'forgotPasswordVerification' */}
        {screen === "forgotPasswordVerification" && (
          <ForgotPasswordVerification
            {...this.props} // Pass down all props to the child component
            email={email} // Pass the email to the child component
          />
        )}
      </Fragment>
    );
  }
}

// Wrap the component with MobX observer for state reactivity
export default observer(ForgotPassword);
