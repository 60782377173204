import React, { useEffect, useState } from "react";
import { Modal, ModalBody, Button, ModalHeader, ModalFooter } from "reactstrap";

const LoginPopupMessage = ({ isOpen, onClose }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Modal
      centered
      isOpen={isOpen}
      toggle={onClose}
      style={{
        maxWidth: isMobile ? "90%" : "40%",
        width: "90%",
      }}
    >
      <ModalHeader
        className="d-flex align-items-center justify-content-center text-center"
        style={{
          color: "#cc1919",
          fontSize: isMobile ? "0.9rem" : "1rem",
          padding: isMobile ? "0.75rem" : "1rem",
        }}
      >
        🔒 <strong>2-Factor Authentication (2FA) Enabled</strong>
      </ModalHeader>
      <ModalBody
        style={{
          fontSize: isMobile ? "0.8rem" : "0.875rem",
          lineHeight: "1.4",
          padding: isMobile ? "0.75rem" : "1rem",
        }}
      >
        <div>
          <p>
            In compliance with SEBI regulations, we’ve implemented 2FA for
            enhanced login security.
          </p>
          <h6
            style={{
              fontSize: "0.9rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
            }}
          >
            What is 2FA?
          </h6>
          <p style={{ marginBottom: "0.3rem" }}>
            2-Factor Authentication (2FA) is a security measure that requires
            two forms of verification:
          </p>
          <p>
            <span>
              1️⃣ <strong>Something You Know</strong> – Your password.
            </span>
            <br />
            <span>
              2️⃣ <strong>Something You Have</strong> – A One-Time Code sent to
              your registered email.
            </span>
          </p>
          <p style={{ marginBottom: "0.3rem" }}>
            This ensures that even if your password is compromised, unauthorized
            access is prevented.
          </p>
          <h6
            style={{
              fontSize: "0.9rem",
              marginBottom: "0.3rem",
              fontWeight: "bold",
            }}
          >
            First-Time Login:
          </h6>
          <p>
            <span>
              1️⃣ Click <strong>"Forgot Password"</strong>, enter your Email ID,
              and submit.
            </span>
            <br />
            <span>
              2️⃣ Check your email for a verification code and set a new
              password.
            </span>
            <br />
            <span>
              3️⃣ Log in with your new password and enter the OTP sent to your
              email.
            </span>
          </p>
          <h6
            style={{
              fontSize: "0.9rem",
              marginBottom: "0.5rem",
              fontWeight: "bold",
            }}
          >
            Key Features:
          </h6>
          <p>
            <span>
              ✔️ <strong>Device-Based Login</strong> – Stays valid until you log
              out.
            </span>
            <br />
            <span>
              ✔️ <strong>Max 5 Devices</strong> – Exceeding this logs out all
              active sessions.
            </span>
          </p>
          <p>
            🔹 Need help? Contact
            <a
              href="mailto:ie.edge@avendusspark.com"
              style={{ color: "#0EAFE2", fontWeight: "bold" }}
            >
              {" "}
              ie.edge@avendusspark.com
            </a>
            .
          </p>
          <p>Thank you for your cooperation!</p>
        </div>
      </ModalBody>
      <ModalFooter className="d-flex align-items-center justify-content-center">
        <Button
          className="btn-primary"
          onClick={onClose}
          style={{
            fontSize: isMobile ? "0.85rem" : "1rem",
            padding: isMobile ? "0.4rem 0.75rem" : "0.4rem 1rem",
          }}
        >
          OKAY
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LoginPopupMessage;
