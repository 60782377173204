import React, { Component } from "react";
import {
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  FormFeedback,
  Spinner,
  Modal,
} from "reactstrap";
import { Link } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import {
  generateBrowserFingerprint,
  updateSessionAndDevicesArray,
} from "../../utils/Browser";
import { PATH_NAME_DOCUMENT_SEARCH } from "../../constants/strings";
import ResponseModal from "../../components/ResponseModal";
import { toast } from "react-toastify";
import "./login.css";
import LoginPopupMessage from "./LoginPopupMessage";

const MAX_SESSION_COUNT = 5; // Maximum allowed session count
const itemStyle = {
  fontSize: "1rem",
  padding: "5px",
};

class Login extends Component {
  /**
   * Initializing state variables to manage component behavior and UI.
   * Timer interval for handling the OTP resend countdown.
   * Will be used to clear the interval on unmount or reset.
   */
  constructor(props) {
    super(props);
    this.state = {
      email: "", // Stores the user's email address.
      password: "", // Stores the user's password.
      otp: "", // Stores the OTP entered by the user.
      loginMethod: "password", // Tracks the selected login method ('otp' or 'password').
      verifyOtp: null, // Determines whether to show OTP verification inputs.
      showTimer: null, // Tracks the visibility of the OTP resend timer.
      devices: [],
      errors: {
        // Stores error states for form validation.
        emailInvalid: false, // Indicates if the entered email is invalid.
        otpInvalid: false, // Indicates if the entered OTP is invalid.
        passwordInvalid: false, // Indicates if the entered password is invalid.
      },
      isLoading: false, // Tracks whether an API call is in progress.
      // emailDisable: false,   // (Commented) Disables email input after login method selection.
      otpAuthUser: null, // Stores the Cognito user object for OTP verification.
      otpTimer: 0, // Countdown timer for enabling OTP resend.
      isResendAllowed: true, // Controls whether OTP resend is allowed.
      maxSessions: false, // Indicates if the maximum session limit is reached.
      modalOpen: false, // Controls the visibility of the modal.
      resendLoader: false, // Tracks loading state during OTP resend actions.
      modal: {
        // Manages the modal's properties and content.
        status: "", // Modal status type (e.g., 'success', 'error').
        title: "", // Modal title.
        text: "", // Modal text/body message.
        buttonText: "", // Text for the modal's button.
        functionalBtn: {
          // Optional functional button properties for the modal.
          link: "", // Link URL (if applicable).
          text: "", // Button text.
          className: "", // CSS class for styling the button.
        },
        metadata: {}, // Additional metadata for modal customization.
      },
      showPopup: localStorage.getItem("popupShown") === "1" ? false : true,
    };
    this.timerInterval = null;
  }

  //Lifecycle methods
  handleClosePopup = () => {
    localStorage.setItem("popupShown", 1); // Set flag in local storage indicating popup has been shown
    this.setState({ showPopup: false }); // Hide the popup
  };

  /**
   * Triggers an analytics event to log a "visit" to the login page.
   * This is delayed by 1 second to ensure that any required setups or
   * dependencies (e.g., analytics tools) are fully loaded before the event fires.
   */
  componentDidMount() {
    setTimeout(() => {
      this.props.amplitudeAnalytics.visit("Login");
    }, 1000);
  }

  /**
   * Resets the error state for form validation fields.
   * This ensures that any previously displayed error messages
   * (e.g., invalid email, OTP, or password) are cleared, allowing
   * the user to retry without seeing outdated errors.
   */
  clearErrorState = () => {
    this.setState({
      errors: {
        emailInvalid: false, // Clears the error state for invalid email.
        otpInvalid: false, // Clears the error state for invalid OTP.
        passwordInvalid: false, // Clears the error state for invalid password.
      },
    });
  };

  /** --- START - MODAL FUNCTIONS --- */
  /**
   * Displays a modal with a custom message to the user.
   *
   * @param {string} status - The status of the modal (e.g., 'success', 'warning', 'error', 'info').
   * @param {string} modalTitle - The title of the modal.
   * @param {string} modalText - The message or body content of the modal.
   * @param {string} buttonText - The text to display on the primary button in the modal.
   * @param {object|null} functionalBtn - Optional functional button configuration with the following properties:
   *    - {string} text: The text to display on the button.
   *    - {function} onClickHandler: The click handler function for the button.
   *    - {string} className: The CSS class for styling the button.
   *
   * This method updates the state to show a modal with the specified content.
   */
  showPopUpMessageModal = (
    status,
    modalTitle,
    modalText,
    buttonText,
    functionalBtn
  ) => {
    this.setState({
      modal: {
        status, // Modal status type (e.g., success or error) for styling or icons.
        title: modalTitle, // Title displayed at the top of the modal.
        text: modalText, // Body text or message displayed in the modal.
        buttonText, // Text for the primary action button in the modal.
        functionalBtn, // Optional configuration for an additional button with custom functionality.
      },
      isModalOpen: true, // Sets the modal visibility to true, ensuring it is displayed.
    });
  };

  /**
   * Logs out the user from all devices and resets their session data.
   *
   * This method performs the following steps:
   * 1. Signs the user in using their email and password to authenticate the action.
   * 2. Updates user attributes to reset session-related custom attributes.
   * 3. Signs the user out globally from all devices.
   * 4. Clears relevant data from local storage.
   * 5. Displays a success message to confirm the action.
   *
   * @returns {Promise<void>} - Resolves when the logout process is complete.
   */
  logoutAllDevices = async () => {
    try {
      // Step 1: Authenticate the user with email and password.
      const user = await Auth.signIn(this.state.email, this.state.password);
      // Step 2: Update custom user attributes to reset session and device data.
      await Auth.updateUserAttributes(user, {
        "custom:sessionCount": "0", // Reset the session count to zero.
        "custom:devices": JSON.stringify([]), // Clear the list of registered devices.
      });
      // Step 3: Sign the user out globally, logging out from all devices.
      await Auth.signOut({ global: true });
      // Step 4: Remove stored email and popup state from localStorage.
      localStorage.removeItem("email");
      localStorage.removeItem("popupShown");
      // Step 5: Display a success message using a toast notification.
      toast.success("All Sessions Logged Out Successfully.");
    } catch (error) {
      // Handle any errors during the logout process.
      console.error("Error logging out from all devices:", error);
      toast.error("Failed to log out from all devices. Please try again.");
    }
  };

  /**
   * Handles the click event for the modal's primary action button.
   *
   * This method performs the following:
   * - Closes the modal by setting `modalOpen` to `false`.
   * - Can be extended to include additional logic if needed for future actions.
   */
  onActionButtonClick = () => {
    this.setState({
      modalOpen: false, // Hides the modal by updating its open state.
    });
  };

  /** --- END - MODAL FUNCTIONS --- */

  /** --- START - INPUT FUNCTIONS --- */

  /**
   * Handles changes to form inputs and performs validation when necessary.
   *
   * This method performs the following:
   * 1. Updates the state with the new value of the input field.
   * 2. If the updated field is the email field, it triggers email validation.
   *
   * @param {Object} e - The event object from the input change event.
   * @param {string} e.target.name - The name of the input field being updated (e.g., 'email', 'password').
   * @param {string} e.target.value - The new value entered in the input field.
   */
  onInputChange = (e) => {
    const { name, value } = e.target;

    // Update the state with the new value of the input field.
    this.setState({ [name]: value }, () => {
      // If the email field is updated, trigger email validation.
      if (name === "email") {
        this.validateEmail();
      }
    });
  };

  /**
   * Validates the email input using a regex pattern and updates the error state.
   *
   * This method performs the following:
   * 1. Uses a regular expression to check if the email input is in a valid format.
   * 2. Updates the `emailInvalid` property in the `errors` state based on the validation result.
   */
  validateEmail = () => {
    const { email } = this.state;

    // Basic email validation regex pattern
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(email); // Test the email against the regex pattern.

    // Update the `emailInvalid` state based on validation result
    this.setState((prevState) => ({
      errors: { ...prevState.errors, emailInvalid: !isValid },
    }));
  };

  /**
   * Sets the login method (OTP or Password).
   *
   * This function updates the state to reflect the selected login method and performs
   * basic validation to ensure the email field is not empty. If the email is invalid,
   * it updates the error state accordingly.
   *
   * @param {string|null} method - The login method selected by the user. Can be "otp", "password", or null.
   */
  setLoginMethod = (method) => {
    const { email } = this.state; // Retrieve the email from state

    // Validate email input
    if (!email.trim()) {
      this.setState((prevState) => ({
        errors: { ...prevState.errors, emailInvalid: true }, // Mark email as invalid
      }));
      return; // Exit the function if email is empty
    }

    // Update state with the selected login method
    this.setState({
      loginMethod: method, // Sets the selected login method ("otp" or "password")
      // emailDisable: true, // Optionally disable the email input (commented out)
      errors: { ...this.state.errors, emailInvalid: false }, // Clear email error if valid
    });
  };

  onKeyPressPassword = (e) => {
    if (e.key === "Enter") {
      this.loginViaPasswordSubmit(e).then(() => {});
    }
  };

  onKeyPressOtp = (e) => {
    if (e.key === "Enter") {
      this.verifyOTPSubmit(e).then(() => {});
    }
  };

  /** --- END - INPUT FUNCTIONS --- */

  /** --- ERRORS FUNCTIONS --- */

  /**
   * Handles errors from Cognito authentication and displays appropriate messages.
   *
   * This method maps error codes and messages to user-friendly responses,
   * displays a modal with the message, and updates the application state.
   *
   * @param {Object} error - The error object received from Cognito.
   * @param {string} error.code - The error code indicating the type of error.
   * @param {string} error.message - The detailed error message from Cognito.
   *
   * TYPES OF COGNITO ERRORS
   * - UserNotFoundException: User does not exist.
   * - NotAuthorizedException: Incorrect username or password.
   * - NotAuthorizedException: User is disabled
   * -UserLambdaValidationException: CreateAuthChallenge failed with error 'email'.
   * -UserNotFoundException: Username/client id combination not found.
   */
  handleCognitoErrors = (error) => {
    // Default response to be used if error code is unrecognized
    const defaultResponse = {
      status: "warning", // Default status for the modal
      fromSpark: "Oops!", // Default title for the modal
      message: "Something went wrong. Please try again later.", // Generic error message
      buttonText: "Okay", // Default button text
      functionalBtn: null, // No secondary button by default
    };

    // Error-specific responses
    const errorResponses = {
      NotAuthorizedException: {
        handler: () => {
          if (error.message === "User is disabled") {
            return {
              message:
                "We couldn't find an active account with that username. Please create a new account, or if you've already registered, wait for administrator approval.",
            };
          }
          if (error.message === "Incorrect username or password.") {
            return {
              message:
                "The password you entered is incorrect. Please try again or use 'Forgot Password' to reset it.",
              buttonText: "Try Again",
            };
          }
          return {
            message:
              "It seems you haven't completed the sign-up process yet. Click here to create a new password for your account.",
            buttonText: "",
            functionalBtn: {
              text: "Resend Link",
              onClickHandler: this.handleResendMail,
              className: "action-button warning",
            },
          };
        },
      },
      UserNotFoundException: {
        handler: () => ({
          fromSpark: "Message from Avendus Spark",
          message:
            "Apologies, we couldn't identify your account. Please sign up by selecting the 'Create an Account' option on the login screen.",
          buttonText: "",
          functionalBtn: {
            text: "Create an Account",
            onClickHandler: () => this.props.history.push("/signup"),
            className: "action-button warning",
          },
        }),
      },
      CodeDeliveryFailureException: {
        handler: () => ({
          message:
            "We're sorry, but we couldn't send the verification code to your email. Please verify the email address you entered and try again.",
          buttonText: "Okay",
        }),
      },
      InternalErrorException: {
        handler: () => ({
          message:
            "Our servers are temporarily unavailable. Please try again later.",
          buttonText: "Try Again",
        }),
      },
      UserLambdaValidationException: {
        handler: () => ({
          message:
            "We're sorry, but we couldn't send the verification code to your email. Please verify the email address you entered and try again.",
          buttonText: "Okay",
        }),
      },
    };

    // Retrieve the response based on the error code
    const response = errorResponses[error.code]
      ? errorResponses[error.code].handler()
      : {};

    // Merge the default response with the specific response
    const finalResponse = {
      ...defaultResponse,
      ...response,
    };

    // Ensure only one button-related property is passed
    const { buttonText, functionalBtn } = finalResponse;
    if (buttonText && functionalBtn) {
      finalResponse.functionalBtn = null; // Prioritize functionalBtn
    }

    // Display the error message modal
    this.showPopUpMessageModal(
      finalResponse.status,
      finalResponse.fromSpark,
      finalResponse.message,
      finalResponse.buttonText,
      finalResponse.functionalBtn
    );

    // Update application state
    this.setState({ modalOpen: true, isLoading: false });

    console.error(error); // Log the error for debugging purposes
  };

  /** --- END - ERRORS FUNCTIONS --- */

  /** --- LOGIN VIA PASSWORD --- */

  /**
   * Handles form submission for login via password.
   *
   * This function authenticates the user using their email and password, checks session limits,
   * and updates user sessions and devices upon successful login. It also handles errors and
   * displays appropriate messages via a modal.
   *
   * @param {Object} e - The event object from the form submission.
   */
  loginViaPasswordSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior
    try {
      this.setState({ isLoading: true }); // Indicate loading state
      this.clearErrorState(); // Clear previous error states

      const { email, password } = this.state;
      // Authenticate user
      const user = await Auth.signIn(email.toLowerCase(), password);

      if (user.signInUserSession) {
        // Retrieve session and device information from user attributes
        const sessionCountCheck =
          user.signInUserSession.idToken.payload["custom:sessionCount"] || 0;
        const devicesAttribute =
          user.signInUserSession.idToken.payload["custom:devices"];

        // Check if user exceeds maximum session limit
        if (sessionCountCheck >= MAX_SESSION_COUNT) {
          await Auth.signOut(); // Log the user out if maximum sessions are exceeded
          this.props.auth.setAuthStatus(false); // Update authentication status

          // Update state to indicate session limit reached
          this.setState({
            maxSessions: true,
            isLoading: false,
            devices: JSON.parse(devicesAttribute), // Parse and set devices attribute
          });
          return;
        } else {
          await Auth.signOut(); // Log the user out
          this.props.auth.setAuthStatus(false); // Update authentication status
          // Call the Send OTP Method
          this.loginViaOTPSubmit(e);
        }
      }
    } catch (error) {
      // Reset state
      this.setState({
        password: "",
        isLoading: false,
      });
      // Display error message in modal
      this.handleCognitoErrors(error);
    }
  };

  /** --- END - LOGIN VIA PASSWORD --- */

  /** --- LOGIN VIA OTP --- */

  /**
   * Starts the OTP resend countdown timer.
   *
   * This function initializes a countdown timer for OTP resend functionality.
   * It updates the `otpTimer` state every second and disables the "Resend OTP"
   * button until the timer expires. Once the countdown reaches zero, the
   * "Resend OTP" button is enabled.
   */
  startOTPTimer = () => {
    const otpExpiryTime = 30; // Countdown duration in seconds
    this.setState({
      otpTimer: otpExpiryTime, // Set initial countdown value
      isResendAllowed: false, // Disable "Resend OTP" functionality
    });

    // Timer interval to update the countdown every second
    this.timerInterval = setInterval(() => {
      this.setState((prevState) => {
        if (prevState.otpTimer > 0) {
          // Decrease the countdown value
          return { otpTimer: prevState.otpTimer - 1 };
        } else {
          // Stop the timer when countdown reaches zero
          clearInterval(this.timerInterval);
          return { isResendAllowed: true }; // Enable "Resend OTP"
        }
      });
    }, 1000); // Timer updates every second
  };

  /**
   * Handles OTP resend logic.
   *
   * This function is triggered when the user clicks the "Resend OTP" button. It
   * re-initiates the OTP authentication flow, sends a new OTP to the user's
   * email address, and displays a confirmation message upon successful OTP
   * delivery.
   *
   * @param {Event} e - The form submit event triggered by the button click.
   */
  handleResendOTP = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior
    try {
      const { email } = this.state; // Retrieves the email from state
      const response = await Auth.signIn(email.toLowerCase()); // Initiates Cognito sign-in flow

      // If the challenge type is CUSTOM_CHALLENGE, OTP is sent successfully
      if (response.challengeName === "CUSTOM_CHALLENGE") {
        this.setState({
          showTimer: true, // Enables the timer display
          modalOpen: true, // Opens the confirmation modal
        });

        // Displays a success message using the modal
        this.showPopUpMessageModal(
          "success", // Status type (e.g., success, warning)
          "Success!", // Modal title
          "A new OTP has been sent to your email address!", // Modal message
          "OK" // Button text
        );

        // Updates component state with the OTP response
        this.setState({
          isLoading: false, // Disables loading indicator
          otpAuthUser: response, // Stores the Cognito user object for OTP verification
        });
      }
    } catch (error) {
      // Logs any errors encountered during the OTP resend process
      console.error("Error resending OTP: ", error.message);
    }
  };

  /**
   * Handles login via OTP submission.
   *
   * This function is triggered when the user attempts to log in using an OTP.
   * It initiates the OTP authentication flow by sending a sign-in request to
   * Cognito and handles the response to guide the user through the OTP
   * verification process.
   *
   * @param {Event} e - The form submit event triggered by the button click.
   */
  loginViaOTPSubmit = async (e) => {
    e.preventDefault(); // Prevents the default form submission behavior
    try {
      this.setState({ isLoading: true }); // Enables loading state
      this.clearErrorState(); // Resets error messages

      const { email } = this.state; // Retrieves the email from state
      const response = await Auth.signIn(email.toLowerCase()); // Initiates Cognito sign-in flow

      // Checks if Cognito returns a CUSTOM_CHALLENGE, indicating OTP flow
      if (response.challengeName === "CUSTOM_CHALLENGE") {
        this.setLoginMethod("otp"); // Sets login method to OTP

        // Updates state to show OTP inputs and initialize the timer
        this.setState({
          verifyOtp: true, // Enables OTP verification input
          isLoading: false, // Disables loading spinner
          otpAuthUser: response, // Stores the Cognito user object
          showTimer: true, // Displays the OTP timer
          modalOpen: true, // Opens the success modal
        });

        // Displays a success message using the modal
        this.showPopUpMessageModal(
          "success", // Status type (e.g., success, warning)
          "Success!", // Modal title
          "An OTP has been sent to your email address!", // Modal message
          "OK" // Button text
        );

        this.startOTPTimer(); // Starts the OTP countdown timer
      }
    } catch (error) {
      // Handles any errors during the OTP submission process
      this.setLoginMethod(null); // Resets login method
      this.setState({
        isLoading: false, // Disables loading spinner
        showTimer: false, // Hides the OTP timer
      });
      this.handleCognitoErrors(error); // Processes and displays error messages
    }
  };

  /**
   * Verifies the OTP entered by the user.
   *
   * This function handles the OTP verification process by sending the user's input
   * to AWS Cognito. Upon successful verification, the function updates the user's
   * authentication status and handles session management. If verification fails,
   * appropriate error messages are displayed.
   *
   * @param {Event} e - The form submit event triggered by the button click.
   */
  verifyOTPSubmit = async (e) => {
    e.preventDefault(); // Prevents default form submission behavior
    try {
      // Set loading state and clear any existing error messages
      this.setState({ isLoading: true, otpErrorMessage: "" });
      this.clearErrorState();

      const { otp } = this.state; // Retrieve the OTP input from state

      // Sends the OTP to Cognito for verification
      const user = await Auth.sendCustomChallengeAnswer(
        this.state.otpAuthUser, // Authenticated user object
        otp // OTP input by the user
      );

      console.log("user ::", user); // Logs the authenticated user object

      // Check if the user session is established
      if (user.signInUserSession) {
        const sessionCountCheck =
          user.signInUserSession.idToken.payload["custom:sessionCount"] || 0; // Check active session count
        const devicesAttribute =
          user.signInUserSession.idToken.payload["custom:devices"]; // Fetch devices data

        // Handle max session limit scenario
        if (sessionCountCheck >= MAX_SESSION_COUNT) {
          await Auth.signOut(); // Sign out the user
          this.props.auth.setAuthStatus(false); // Update authentication status

          // Update state to reflect session limit and display relevant UI
          this.setState({
            maxSessions: true,
            isLoading: false,
            devices: JSON.parse(devicesAttribute), // Parse devices data
            otpAuthUser: null, // Clear OTP user object
          });
          return; // Stop further execution
        } else {
          // Generate a unique browser fingerprint for the session
          generateBrowserFingerprint(async (fingerprint) => {
            await updateSessionAndDevicesArray(
              fingerprint, // Browser fingerprint
              Auth, // Cognito Auth instance
              devicesAttribute, // Existing devices data
              sessionCountCheck, // Current session count
              user // Authenticated user object
            );

            // Update authentication state and redirect the user
            this.props.auth.setUser(user); // Store user in auth context
            this.props.auth.setAuthStatus(true); // Mark the user as authenticated
            this.setState({ isLoading: false, otpAuthUser: null }); // Reset loading state and clear OTP user

            const redirectUrl =
              this.props.store.redirectRoute || PATH_NAME_DOCUMENT_SEARCH; // Determine redirect route
            this.props.history.push(redirectUrl); // Navigate to the desired page

            // Reload the page to ensure all components are properly initialized
            setTimeout(() => {
              window.location.reload(false);
            });

            // Log an analytics event for the successful login
            this.props.amplitudeAnalytics.login(this.state.email);
          });
        }
      } else {
        // Handle invalid OTP scenario
        setTimeout(() => {
          this.setState({
            errors: { ...this.state.errors, otpInvalid: true }, // Mark OTP as invalid
            isLoading: false, // Reset loading state
          });
        }, 3000); // Delay to simulate processing time
      }
    } catch (error) {
      // Handle errors during OTP verification
      this.setLoginMethod(null); // Reset login method
      this.setState({
        otp: "", // Clear OTP input field
        isLoading: false, // Disable loading state
        showTimer: false, // Hide OTP timer
      });
      this.handleCognitoErrors(error); // Display appropriate error messages
    }
  };

  /** --- END - LOGIN VIA OTP --- */

  render() {
    const {
      email,
      password,
      otp,
      loginMethod,
      verifyOtp,
      showTimer,
      errors,
      isLoading,
      otpTimer,
      isResendAllowed,
      maxSessions,
      modalOpen,
      resendLoader,
      modal,
    } = this.state;

    return (
      <>
        <Form>
          {loginMethod === "password" && (
            <>
              <FormGroup>
                <div className="label-container">
                  <span className="field-label">Email</span>
                </div>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  placeholder="example@abc.com"
                  onChange={this.onInputChange}
                  onBlur={this.validateEmail} // Validate on blur or after the user finishes typing
                  invalid={errors.emailInvalid}
                />
                <FormFeedback>
                  {errors.emailInvalid ? "Please enter a valid Email" : ""}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <div className="label-container">
                  <span className="field-label">Password</span>
                </div>
                <Input
                  type="password"
                  name="password"
                  value={password}
                  onChange={this.onInputChange}
                  invalid={errors.passwordInvalid}
                  onKeyPress={this.onKeyPressPassword}
                />
                <FormFeedback>
                  {errors.passwordInvalid
                    ? "The password you entered is incorrect. Please try again or use 'Forgot Password' to reset it."
                    : ""}
                </FormFeedback>
              </FormGroup>
              <div className="secondary-inputs">
                <FormGroup check className="keep-me-signed-in-container" inline>
                  <Label check>
                    <Input
                      type="checkbox"
                      name="keepMeLoggedIn"
                      defaultChecked={true}
                    />
                    <span className="keep-me-signed-in">Keep me signed in</span>
                  </Label>
                </FormGroup>
                <span className="forgot-password-button">
                  <Link to="/forgot_password">Forgot Password?</Link>
                </span>
              </div>
              <Button
                style={{
                  backgroundColor: "#2b2e32",
                  margin: "10px 0",
                  width: "100%",
                }}
                onClick={(e) => this.loginViaPasswordSubmit(e)}
              >
                {!isLoading ? "LOGIN" : <Spinner size="sm" color="white" />}
              </Button>
            </>
          )}

          {/* OTP Login */}
          {loginMethod === "otp" && verifyOtp && (
            <>
              <FormGroup>
                <div className="label-container">
                  <span className="field-label">OTP</span>
                </div>
                <Input
                  type="text"
                  name="otp"
                  value={otp}
                  onChange={this.onInputChange}
                  invalid={errors.otpInvalid}
                  onKeyPress={this.onKeyPressOtp}
                />
                <FormFeedback>
                  {errors.otpInvalid
                    ? "The OTP is incorrect. Please try again using the 'NEW OTP' sent to your email."
                    : ""}
                </FormFeedback>
              </FormGroup>

              {showTimer && (
                <div
                  style={{
                    marginTop: "5px",
                    textAlign: "end",
                    fontSize: "12px",
                  }}
                >
                  {otpTimer > 0 ? (
                    <span>
                      Resend OTP in <b>{otpTimer}s</b>
                    </span>
                  ) : (
                    <Button
                      style={{
                        marginTop: "5px",
                        fontSize: "12px",
                        padding: "0",
                        color: "#F28C28",
                        fontWeight: "bolder",
                      }}
                      color="link"
                      onClick={this.handleResendOTP}
                      disabled={!isResendAllowed}
                    >
                      Resend OTP
                    </Button>
                  )}
                </div>
              )}

              <Button
                style={{
                  backgroundColor: "#2b2e32",
                  margin: "10px 0",
                  width: "100%",
                }}
                onClick={this.verifyOTPSubmit}
                disabled={isLoading}
              >
                {!isLoading ? (
                  "VERIFY OTP"
                ) : (
                  <Spinner size="sm" color="white" />
                )}
              </Button>
            </>
          )}

          {/* Create Account Link */}
          <div style={{ margin: "0.5em 0 0 0", fontSize: "0.8rem" }}>
            <div>
              <Link className="create-account-link" to="/signup">
                Create an Account
              </Link>
            </div>
          </div>

          {/* Terms and Conditions Text */}
          <div
            style={{
              fontSize: "0.8rem",
              marginTop: "1rem",
              lineHeight: "1rem",
            }}
          >
            <p>
              By signing up, I agree to the <b>Terms and Conditions</b>,
              acknowledge having read the <b>Privacy Policy</b>, and click{" "}
              <b>
                <a
                  href="https://www.avendus.com/india/register-complaint"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#000",
                  }}
                >
                  here
                </a>
              </b>{" "}
              to register a complaint.
            </p>
          </div>
        </Form>

        {/* Max Sessions Modal Popup */}
        {maxSessions && (
          <Modal
            centered={true}
            isOpen={maxSessions}
            className="terms-n-conditions-modal"
            backdrop="static"
            toggle={() => this.setState({ maxSessions: false })}
            scrollable={true}
          >
            <div>
              <div className="px-3 pt-3 d-flex justify-content-end">
                <div>
                  <Button
                    type="button"
                    className="btn-close bg-white"
                    onClick={() => this.setState({ maxSessions: false })}
                  ></Button>
                </div>
              </div>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 20px",
                }}
              >
                <h4
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  You seem to be already logged in from the following devices
                </h4>
                <div
                  style={{
                    margin: "0 auto",
                    textAlign: "left",
                  }}
                >
                  {this.state.devices?.map((d, index) => (
                    <h5 key={index} style={itemStyle}>
                      {`${index + 1}. `}
                      <strong>Device:</strong> {d?.device || d?.os},&nbsp;&nbsp;
                      <strong>Browser:</strong> {d?.browser},&nbsp;&nbsp;
                      <strong>OS:</strong> {d?.os}
                    </h5>
                  ))}
                </div>
                <p style={{ color: "red" }}>
                  {`We allow only ${MAX_SESSION_COUNT} simultaneous login sessions per user. If you continue, we will log you out from the above devices.`}
                </p>
                <Button
                  color="danger"
                  onClick={() => {
                    this.setState({ maxSessions: false });
                    this.logoutAllDevices();
                  }}
                >
                  CONTINUE
                </Button>
              </div>
            </div>
          </Modal>
        )}

        {/* Error Message Modal Popup */}
        {modalOpen && (
          <ResponseModal
            modal={modalOpen}
            backdrop={true}
            modalClass="response-modal"
            titleClass={"title " + modal.status}
            modalTitle={modal.title}
            textClass="text"
            modalText={modal.text}
            buttonClass={"action-button " + modal.status}
            buttonText={modal.buttonText}
            onClickAction={this.onActionButtonClick}
            functionalBtn={modal.functionalBtn}
            isBtnLoading={resendLoader}
            type={modal.status}
          />
        )}

        <LoginPopupMessage
          isOpen={this.state.showPopup || false}
          onClose={this.handleClosePopup}
        />
      </>
    );
  }
}

export default Login;
