import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import {
  Button,
  Form,
  FormGroup,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap";
import Validate from "../../utils/FormValidation";
import Auth from "@aws-amplify/auth";
import ResponseModal from "../../components/ResponseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";

class ForgotPasswordVerification extends Component {
  state = {
    code: "", // Verification code entered by the user
    newPassword: "", // New password
    confirmPassword: "", // Password confirmation
    isLoading: false, // Whether the request is loading
    isModalOpen: false, // Whether the modal is open
    modal: {
      // Modal content
      status: "",
      title: "",
      text: "",
      buttonText: "",
    },
    errors: {
      // Form validation errors
      codeInvalid: false,
      newPasswordInvalid: false,
      confirmPasswordInvalid: false,
      cognito: null,
    },
  };

  // Clears error state to reset form errors
  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        codeInvalid: false,
        newPasswordInvalid: false,
        confirmPasswordInvalid: false,
      },
    });
  };

  // Handles the password verification (submit process)
  passwordVerificationHandler = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    let self = this;
    this.setState({ isLoading: true });

    // Clears previous validation errors
    this.clearErrorState();

    // Validates the form data
    const errors = Validate("forgotPasswordVerification", this.state);

    // Validating new password using regex pattern
    const { newPassword, confirmPassword } = this.state;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
    if (!passwordPattern.test(newPassword)) {
      errors.newPasswordInvalid = true;
      this.setState({
        errors: { ...errors },
        isLoading: false,
      });
      return;
    } else {
      errors.newPasswordInvalid = false;
      this.setState({
        errors: { ...errors },
        isLoading: false,
      });
    }

    // Validates if the new password matches the confirm password
    if (newPassword !== confirmPassword) {
      errors.confirmPasswordInvalid = true;
      this.setState({
        errors: { ...errors },
        isLoading: false,
      });
      return;
    } else {
      errors.confirmPasswordInvalid = false;
      this.setState({
        errors: { ...errors },
        isLoading: false,
      });
    }

    let message =
      "We couldn't reset your Password at the moment. Please try again after sometime or contact your account manager.";

    // AWS Cognito integration for resetting password
    try {
      // Using Amplify's forgotPasswordSubmit method
      await Auth.forgotPasswordSubmit(
        this.props.email, // User's email
        this.state.code, // Verification code
        this.state.newPassword // New password
      );
      this.setState({ isLoading: false });

      // Show success modal after password reset
      this.showPopUpMessageModal(
        "success",
        "Done!",
        "Your Password has been reset successfully. Redirecting to login page now.",
        "OKAY"
      );

      // Redirect to login page after a delay
      setTimeout(function () {
        self.props.history.push("/login");
      }, 2000);
    } catch (error) {
      // Handle different error cases from Cognito
      if (error?.code && error?.message) {
        // Display specific error message if 'code' and 'message' exist in the error
        message = error.message;
      } else if (typeof error === "string") {
        // If the error is a string, directly assign it to the message
        message = error;
      } else {
        // Handle any other unexpected error cases
        message = "An unknown error occurred. Please try again later.";
      }
      // Show modal with error message
      this.showPopUpMessageModal("warning", "Oops!", message, "TRY AGAIN");
      this.setState({ isLoading: false });
      console.log(error); // Log the error for debugging
    }
  };

  // Function to display modal messages
  showPopUpMessageModal = (status, modalTitle, modalText, buttonText) => {
    this.setState({
      isModalOpen: true,
      modal: {
        status: status,
        title: modalTitle,
        text: modalText,
        buttonText: buttonText,
      },
    });
  };

  // Handles input field change
  onInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value.trim(),
    });
  };

  // Triggers password verification on Enter key press
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.passwordVerificationHandler(e);
    }
  };

  // Handles modal button click and closes the modal
  onActionButtonClick = () => {
    this.setState({
      isModalOpen: false,
    });
  };

  render() {
    return (
      <Fragment>
        <Form>
          {/* Verification Code input */}
          <FormGroup>
            <div className="label-container">
              <span className="field-label">Verification Code</span>
            </div>
            <Input
              type="text"
              name="code"
              onChange={this.onInputChange}
              invalid={this.state.errors.codeInvalid}
              onKeyPress={this.onKeyPress}
            />
            <FormFeedback>
              {this.state.errors.codeInvalid ? "Please Enter a valid Code" : ""}
            </FormFeedback>
          </FormGroup>

          {/* New Password input */}
          <FormGroup>
            <div className="label-container">
              <span className="field-label">
                New Password
                <FontAwesomeIcon
                  className="password-info"
                  icon="info-circle"
                  id="email-info-popover"
                />
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target="email-info-popover"
                >
                  <PopoverBody>
                    Please create a Password that contains at least 8
                    characters, one uppercase letter, one lowercase letter, one
                    number, and one special character (!@#$).
                  </PopoverBody>
                </UncontrolledPopover>
              </span>
            </div>
            <Input
              type="password"
              name="newPassword"
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={this.state.errors.newPasswordInvalid}
            />
            <FormFeedback>
              {this.state.errors.newPasswordInvalid
                ? "Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character(!@#$)."
                : ""}
            </FormFeedback>
          </FormGroup>

          {/* Confirm Password input */}
          <FormGroup>
            <div className="label-container">
              <span className="field-label">Confirm Password</span>
            </div>
            <Input
              type="password"
              name="confirmPassword"
              onChange={this.onInputChange}
              onKeyPress={this.onKeyPress}
              invalid={this.state.errors.confirmPasswordInvalid}
            />
            <FormFeedback>
              {this.state.errors.confirmPasswordInvalid
                ? "Passwords Don't match"
                : ""}
            </FormFeedback>
          </FormGroup>

          {/* Submit Button */}
          <Button
            style={{
              backgroundColor: "#2b2e32",
              margin: "10px 0",
              width: "100%",
            }}
            onClick={this.passwordVerificationHandler}
          >
            {!this.state.isLoading ? "SUBMIT" : ""}
            {this.state.isLoading && <Spinner size="sm" color="white" />}
          </Button>

          {/* Link to go back to login */}
          <div style={{ margin: "-5px 0 -2px 0" }}>
            <Link className="back-to-login-link" to="/login">
              {" "}
              Go back to Login{" "}
            </Link>
          </div>
        </Form>

        {/* Response Modal */}
        {this.state.isModalOpen && (
          <ResponseModal
            modal={this.state.isModalOpen}
            backdrop="static"
            modalClass="response-modal"
            titleClass={"title " + this.state.modal.status}
            modalTitle={this.state.modal.title}
            textClass="text"
            modalText={this.state.modal.text}
            buttonClass={"action-button " + this.state.modal.status}
            buttonText={this.state.modal.buttonText}
            onClickAction={this.onActionButtonClick}
            type={this.state.modal.status}
          />
        )}
      </Fragment>
    );
  }
}

export default observer(ForgotPasswordVerification);
